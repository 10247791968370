import * as React from 'react';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_ACCOUNT } from '../../utils/mutations';

//import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ButtonLink, StyledLink, CallableLink, ExternalLink } from '../styles/Link.styled';
import Auth from '../../utils/auth';
//import SocialMenu from '../MUI/SocialMenu';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { FlexDiv } from '../styles/Divs.styled';
import { ServicesImg } from '../styles/Images.styled';
import drawerImg960 from '../../assets/imgs/when-you-960.png';
import drawerImg500 from '../../assets/imgs/When-you-500.png';


export default function BasicMenu({handleOpen,shouldDelete,userPics,profilePicInfo}) {
   
  /**NOTE
   * handleOpen which is for the modal are both passed from the header as well as shouldDelete
   * which is a state variable determining wheter or not delete functionality for a users
   * profile should be ran handle open is called below in the menu item that houses delete account text
   */
  //const [anchorEl, setAnchorEl] = React.useState(null);
   // const open = Boolean(anchorEl);

    /*const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };*/

  

    // mutatiton for deleting a user A.K.A. Contact
const [deleteContact] = useMutation(DELETE_ACCOUNT);



  // this useEffect depends on the shouldDelete variable coming from the header component
  // it will call the delete account function if the variable is true and close the modal
  // and menu otherwise. The shouldDelete is set in modal buttons
  useEffect(() => {
    if(shouldDelete === true) {
       // get user id from token
    const userId = Auth.getProfile().data._id;

      const deleteAccount = async (usersId) => {
        await deleteContact({
          variables: {
            contactId: usersId
          }
        })
        // destroy token go to home page the user is now gone forever
         Auth.logout()
      };  

      /*this function will delete all user picture files from s3 */
   const deleteUserImages = async (userPics,profilePicInfo) => {
      let vidDelete;
      const s3Import = await import("../../utils/s3");
      
             const {s3Delete} = s3Import;
            vidDelete = s3Delete;
       /*turn our stringified data back to an object */
       const proPicDeleteObj = JSON.parse(profilePicInfo);
         /**destructure the delete info object passed from profilePage component originally */
         const {Bucket,Key} = proPicDeleteObj;
    vidDelete(Bucket,Key);
    // for of loop to delete project pictures
    for (const picture of userPics) {
   const {Key,Bucket} = picture;
     vidDelete(Bucket,Key);
    };

  };

     toggleDrawer(false);
     deleteUserImages(userPics,profilePicInfo);
     deleteAccount(userId);

    }else{
      toggleDrawer(false);
    }
  },[shouldDelete,deleteContact,userPics,profilePicInfo]);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
{Auth.loggedIn() ? (
          <div>
            <MenuItem onClick={toggleDrawer(false)}><CallableLink color="white" href="tel:+18014939012"><pre>Call Now: 801-493-9012</pre></CallableLink></MenuItem>
            <Divider sx={{background:'#3d6098'}}/>
            <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to={`/profile/${Auth.getProfile().data._id}`}>Dashboard</StyledLink></MenuItem>
            <Divider sx={{background:'#3d6098'}}/>
           <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/resources">Resources</StyledLink></MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/pricing">Pricing</StyledLink></MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/about">About</StyledLink></MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to='/services'>Services</StyledLink></MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem onClick={toggleDrawer(false)}><ExternalLink txtDecoration='underline'  color="white" target='_blank' href='https://www.topdev.blog/'>Blog</ExternalLink></MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem>
           <ButtonLink  to='/' onClick={() => Auth.logout()} >Logout</ButtonLink>
           </MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           <MenuItem sx={{color:'#f04b4c'}} onClick={() => handleOpen()}>Delete Account</MenuItem>
           <Divider sx={{background:'#3d6098'}}/>
           {/*<MenuItem><SocialMenu/></MenuItem>*/}
           <FlexDiv 
   height='unset'
  >
     <picture className='menu-pic'>
                <source
        media="(min-width:1366px)"
        srcSet={drawerImg960}
     />
     
     <source
        media="(min-width:768px)"
        srcSet={drawerImg960}
     />
   <source
       srcSet={drawerImg500}
     />
    <ServicesImg 
    src={drawerImg960} 
       
    marginTop='8%' 
    radius= '1%' 
    alt='future web and software development for businesses is at TopDev Tech utahs best web and software development agency'/>
   </picture>     
   </FlexDiv>
           </div>
        ):( 
          <div>
           
        
        <MenuItem onClick={toggleDrawer(false)}><CallableLink color="white" href="tel:+18014939012"><pre>Call Now: 801-493-9012</pre></CallableLink></MenuItem>  
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to='/seo-templates'>SEO Ready Sites</StyledLink></MenuItem> 
        <Divider sx={{background:'#3d6098'}}/> 
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/resources">Resources</StyledLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/pricing">Pricing</StyledLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to="/about">About</StyledLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to='/services'>Services</StyledLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><StyledLink color="white" to='/login'>Login</StyledLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        <MenuItem onClick={toggleDrawer(false)}><ExternalLink txtDecoration='underline' color="white" target='_blank' href='https://www.topdev.blog/'>Blog</ExternalLink></MenuItem>
        <Divider sx={{background:'#3d6098'}}/>
        {/*<MenuItem><SocialMenu/></MenuItem>*/}
        <FlexDiv 
   height='unset'
  >
     <picture>
                <source
        media="(min-width:1366px)"
        srcSet={drawerImg960}
     />
     
     <source
        media="(min-width:768px)"
        srcSet={drawerImg960}
     />
   <source
       srcSet={drawerImg500}
     />
    <ServicesImg 
    src={drawerImg960} 
      
    marginTop='8%' 
    radius= '1%' 
    alt='future web and software development for businesses is at TopDev Tech utahs best web and software development agency'/>
   </picture>     
   </FlexDiv>
        </div>
        )
      }
    </Box>
  );

  return (
    <div>
      <Button 
      sx={{
        "&:hover": {color:"#e7e7e7",
       backgroundColor:'rgba(61, 96, 152,0.7)'}, 
       color: 'white', 
      marginTop:{xs:'8%', sm:'9%',md:'5%',lg:'8%'},
      fontSize:{xs:'1.7rem',sm:'1.7rem',md:'1.8rem',lg:'2.2rem',xl:'2.5rem'},
       fontWeight:'bolder',
       //fontFamily:'courier New, Courier, monospace'
       fontFamily:'manhattan'
      }}
      onClick={toggleDrawer(true)}>Explore</Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
  
  
}
//rgb(111 161 241)
/*
*/
/*return (
    <div>
      <Button
      
      onClick={handleDrawerToggle}
        sx={{
          "&:hover": {color:"#e7e7e7",
         backgroundColor:'rgba(61, 96, 152,0.7)'}, 
         color: 'white', 
        marginTop:{xs:'8%', sm:'9%',md:'5%',lg:'8%'},
        fontSize:{xs:'1.7rem',sm:'1.7rem',md:'1.8rem',lg:'2.2rem',xl:'2.5rem'},
         fontWeight:'bolder',
         //fontFamily:'courier New, Courier, monospace'
         fontFamily:'manhattan'
        }}
      >
        Explore
      </Button>
      <Drawer
       anchor='right'
       open={mobileOpen}
       //onTransitionEnd={handleDrawerTransitionEnd}
       onClose={handleDrawerClose}
       ModalProps={{
         keepMounted: true, // Better open performance on mobile.
       }}
       sx={{
         display: { xs: 'block', sm: 'none' },
         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
       }}
     
      >
        {Auth.loggedIn() ? (
          <div>
            <MenuItem onClick={handleDrawerClose}><CallableLink color="white" href="tel:+18014939012"><pre>Call Now: 801-493-9012</pre></CallableLink></MenuItem>
            <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to={`/profile/${Auth.getProfile().data._id}`}>Dashboard</StyledLink></MenuItem>
           <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/resources">Resources</StyledLink></MenuItem>
           <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/pricing">Pricing</StyledLink></MenuItem>
           <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/about">About</StyledLink></MenuItem>
           <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to='/services'>Services</StyledLink></MenuItem>
           <MenuItem onClick={handleDrawerClose}><ExternalLink color="white" target='_blank' href='https://www.topdev.blog/'>Blog</ExternalLink></MenuItem>
           <MenuItem>
           <ButtonLink  to='/' onClick={() => Auth.logout()} >Logout</ButtonLink>
           </MenuItem>
           <MenuItem sx={{color:'#f04b4c'}} onClick={() => handleOpen()}>Delete Account</MenuItem>
           /*<MenuItem><SocialMenu/></MenuItem>
           </div>
        ):( 
          <div>
        <MenuItem onClick={handleDrawerClose}><CallableLink color="white" href="tel:+18014939012"><pre>Call Now: 801-493-9012</pre></CallableLink></MenuItem>    
        <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/resources">Resources</StyledLink></MenuItem>
        <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/pricing">Pricing</StyledLink></MenuItem>
        <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to="/about">About</StyledLink></MenuItem>
        <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to='/services'>Services</StyledLink></MenuItem>
        <MenuItem onClick={handleDrawerClose}><StyledLink color="white" to='/login'>Login</StyledLink></MenuItem>
        <MenuItem onClick={handleDrawerClose}><ExternalLink color="white" target='_blank' href='https://www.topdev.blog/'>Blog</ExternalLink></MenuItem>
        {/*<MenuItem><SocialMenu/></MenuItem>
        </div>
        )
      }
      </Drawer>
    </div>
    
    );*/