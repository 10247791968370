import styled from "styled-components";

export const ProfileImg = styled.img`
border-radius: ${props => props.radius} ;
width: ${props => props.width};
height:${props => props.height ? props.height : '10vh'};
border: 2px solid #e7e7e7 ;
box-shadow: 0 0 10px #e7e7e7;
margin-bottom: 1%;
margin-top: ${props => props.marginTop};
/**added Aug 29 2023 query used for logo image in project details*/
@media screen and (min-width: 540px) {
   height: ${props => props.height540};
}
@media screen and (min-width: 768px) {
   width:${props => props.width768 ? props.width768 : '90%'};
   height:${props => props.height768 ? props.height768 : '8vh'};
 
}
@media screen and (min-width: 1024px) {
   width:${props => props.width1024 ? props.width1024 : '100%'};
   height:${props => props.height1024 ? props.height1024 : '20vh'};
 
}
@media screen and (min-width: 1280px) {
   width:${props => props.width1280 ? props.width1280 : '100%'};
   height:${props => props.height1280 ? props.height1280 : '15vh'};
   
}
@media screen and (min-width: 1920px) {
   width:${props => props.width1920};
   height:${props => props.height1920};
   
}
`;
export const SpinnerImg = styled.img`
border-radius: ${props => props.radius} ;
width: ${props => props.width};
margin-bottom: 1%;
height: ${props => props.height};
`;
export const ServicesImg = styled.img`
border-radius: ${props => props.radius} ;
width: 100%;
margin-bottom: 1%;
margin-top:  ${props => props.marginTop};
height: ${props => props.height};
@media screen and (min-width: 1366px) {
   width:${props => props.width1366};
   height:${props => props.height1366};
   
}
//border: 1px solid rgba(61,96,152,1);
//box-shadow:  0 0 10px white;
object-fit: cover;
`;

export const HeaderImg = styled.img`
//border-radius: 50%;
//border: 2px solid black;
/*width: 20%;*/
width: 49%;
margin-top: 2%;
margin-bottom: 3%;
@media screen and (min-width: 360px) {
   width: 39%;
}

@media screen and (min-width: 600px) {
   width: 30%;
}
@media screen and (min-width: 1024px) {
   margin-bottom: 3%;
}
  

@media screen and (min-width: 1366px) {
   width: 35%;
}
@media screen and (min-width: 1536px) {
   width: 45%;
}
`;

export const ErrorImg = styled.img`
border: 2px solid rgb(135 174 237) ;
box-shadow: 0 0 10px #e7e7e7;
@media screen and (min-width: 540px) {
   width: 70%;
}
@media screen and (min-width: 912px) {
   width: 80%;
   border: 3px solid rgb(135 174 237) ;
   box-shadow: 0 0 18px #e7e7e7;
}
@media screen and (min-width: 1024px) {
   width: 40%;
   border: 2px solid rgb(135 174 237) ;
   border-radius: 4px;
}
`;

/**img tag for social links*/
export const SocialPic = styled.img`
width:70%;

@media screen and (min-width:1024px) {
   width: 60%;
}
`;

/**homepage picture content image components */
export const HomePagePic = styled.img`
margin-top:  ${props => props.marginTop};
margin-bottom:  ${props => props.marginBtm};
border-radius: ${props => props.borderRadius};
box-shadow: ${props => props.boxShadow};
width:${props => props.width ? props.width:'100%'};
@media screen and (min-width: 768px) {
   margin-bottom: ${props => props.marginBtm768};
    margin-top: ${props => props.marginTop768};
}
@media screen and (min-width: 820px) {
   margin-bottom: ${props => props.marginBtm820};
    margin-top: ${props => props.marginTop820};
}
@media screen and (min-width: 912px) {
   margin-bottom: ${props => props.marginBtm912};
    margin-top: ${props => props.marginTop912};
}
@media screen and (min-width: 1024px) {
   margin-bottom: ${props => props.marginBtm1024};
    margin-top: ${props => props.marginTop1024};
}
@media screen and (min-width: 1280px) {
   margin-bottom: ${props => props.marginBtm1280};
    margin-top: ${props => props.marginTop1280};
}
@media screen and (min-width: 1920px) {
   margin-bottom: ${props => props.marginBtm1920};
    margin-top: ${props => props.marginTop1920};
    width: 50%;
}
`;

/**priceModal img */
export const PriceModalImg = styled.img`
//border-radius: 50%;
//border: 2px solid black;
//background-color: rgb(0,0,0);
/*width: 20%;*/
width: 29%;
@media screen and (min-width: 600px) {
   width: 20%;
}
/**medie querie min-width 1024px */
@media screen and (min-width: 1024px) {
   width: 13%;
   margin-top: 1%;
}
@media screen and (min-width: 1366px) {
   width: 18%;
}
@media screen and (min-width: 1536px) {
   width: 20%;
}
`;
export const LogoImg = styled.img`
width: 40%;
margin-top: 2%;
background-color: black;
border-radius: 100px;
@media screen and (min-width: 540px) {
   width: 25%;
   margin-top: 3%;
}
`;
/**jan 24 */
export const PriceImg = styled.img`
border-radius: 8px ;
width: 100%;
margin-bottom: 1%;
margin-top:  ${props => props.marginTop};
height: ${props => props.height};
@media screen and (min-width: 1366px) {
   width:${props => props.width1366};
   height:${props => props.height1366};
   
}

object-fit: cover;
`;

/**JAN 23 2024' */
export const NewHeroImg = styled.img`
width: 100%;
object-fit: cover;
`;
export const ReviewImg = styled.img`
width: 100%;
height: 300px;
object-fit: cover;
object-position: 10% 80% ;
@media screen and (min-width: 768px) {
   height: auto;

object-position: none;
}
`;