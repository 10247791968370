import styled from "styled-components";

export const Toe = styled.footer `
width: 100%;
//background-color: #e7e7e7;
background-color: black;
display: flex;
flex-direction: column;
justify-content: space-between;
font-family: 'manhattan';
align-items: center;
/**These styles keep the footer at the bottom of page they work in conjunction with  
position: relative; and min-height on the body element also it should be noted that my main container elements
have some bottom padding to maintain some space I want to note the footer was at a height of 2.5rem but I changed it to auto*/
position: absolute;
bottom: 0;
width: 100%;
height: auto; 
font-size: 1.5rem;
padding-bottom: .3em;
/**this caused overflow issues I originally had it to cover small blue outline on some devices */
/*border: 4px solid #e7e7e7;*/
//@media screen and (min-width: 540px) {
   // flex-direction: row;
//}
`