import styled from 'styled-components';

export const Head = styled.header `
  width: 100%;
  //background-color: #e7e7e7;
  background-color: black;
  display: flex;
  justify-content: space-around;
`;

export const StickyHeader = styled.header`
position: sticky;
top: 0;
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const Nav = styled.nav `
width: 50%;
display: flex;
justify-content: right;
`;

