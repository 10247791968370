import { Link,NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
 text-decoration: underline;
 color: ${props => props.color ? props.color: 'blue'};

`;
/**added june 2023 */
export const TopDevProccessLink =  styled(Link)`
text-decoration: none;
`;

export const InternalPageLink = styled(Link)`
color: #fee084;
`;

export const ResourceNavLink = styled(NavLink)`
  //margin-left: 10%;
  margin: 0% 0% 5% 5%;
    color: white;
    &.active{
        color: #fee084;
    }
    @media screen and (min-width:1366px) {
        font-size: 1.2em;
    }
`;
/**added june 2023 */

export const ButtonLink = styled(Link)`
    margin:${props => props.margin ? props.margin: '10% .88% 1% 1%' };
    padding: 11px;
    background-color: ${props => props.bgcolor ? props.bgcolor: '#f04b4c'};
    font-size: 1em;
    font-weight: 620;
    color:${props => props.color ? props.color:'white'};
    opacity:${props => props.opacity ? props.opacity: '.55'};
    border: ${props => props.border ? props.border:'1px solid white'};
    border-radius: 8px;
    text-decoration: none;
    /**JAN 23 2024*/
    text-align:${props => props.txtalign};
    @media screen and (min-width: 360px) {
    margin:${props => props.margin360 };
     };
    @media screen and (min-width: 390px) {
    margin:${props => props.margin390 };
     };
    @media screen and (min-width: 430px) {
    margin:${props => props.margin430 };
     };
  


    /**Added September 7 2023*/
    width: ${props => props.width};
    &:hover {
    opacity: 1;
    background-color:${props => props.hoverBg ? props.hoverBg: 'rgba(61,96,152)'} ;
}
    /**medie querie min-width 768px */
    @media screen and (min-width: 540px) {
    /*turned to props for skills page */
    margin:${props => props.margin540 ? props.margin540: '3% .88% 1% 1%'};  
    /**made for use in skills page also being used in the porfolio page*/
    padding: ${props => props.padding540}; 
    font-size: ${props => props.fontsize540};
}
    /**medie querie min-width 768px */
@media screen and (min-width: 768px) {
    /*turned to props for skills page */
    margin:${props => props.margin768 ? props.margin768: '3% .88% 1% 1%'};  
    /**made for use in skills page also being used in the porfolio page*/
    padding: ${props => props.padding768}; 
    font-size: ${props => props.fontsize768};
}
 /**made for use in skills page*/
 @media screen and (min-width: 820px) {
    padding: ${props => props.padding820}; 
    font-size: ${props => props.fontsize820}; 
}
@media screen and (min-width: 1024px) {
width: ${props => props.width1024 };
/**Added JAN 23 2024*/
margin:${props => props.margin1024 };
}
@media screen and (min-width: 1280px) {
width: ${props => props.width1280 };
/**Added JAN 23 2024*/
margin:${props => props.margin1280 };
}

/*made for services originally */
@media screen and (min-width: 1366px) {
    padding: ${props => props.padding1366}; 
    font-size: ${props => props.fontsize1366};
    margin:${props => props.margin1366 };
}
@media screen and (min-width: 1920px) {
    padding: ${props => props.padding1920}; 
    font-size: ${props => props.fontsize1920};
    margin:${props => props.margin1920 };
}
@media screen and (min-width: 2400px) {
   
    margin:${props => props.margin2400 };
}
`;
/*Added june 2024 for GHL calendar link*/
export const ExternalButtonLink = styled.a`
    margin:${props => props.margin ? props.margin: '10% .88% 1% 1%' };
    padding: 11px;
    background-color: ${props => props.bgcolor ? props.bgcolor: '#f04b4c'};
    font-size: 1em;
    font-weight: 620;
    color:${props => props.color ? props.color:'white'};
    opacity:${props => props.opacity ? props.opacity: '.55'};
    border: ${props => props.border ? props.border:'1px solid white'};
    border-radius: 8px;
    text-decoration: none;
    /**JAN 23 2024*/
    text-align:${props => props.txtalign};
    @media screen and (min-width: 360px) {
    margin:${props => props.margin360 };
     };
    @media screen and (min-width: 390px) {
    margin:${props => props.margin390 };
     };
    @media screen and (min-width: 430px) {
    margin:${props => props.margin430 };
     };
  


    /**Added September 7 2023*/
    width: ${props => props.width};
    &:hover {
    opacity: 1;
    background-color:${props => props.hoverBg ? props.hoverBg: 'rgba(61,96,152)'} ;
}
    /**medie querie min-width 768px */
    @media screen and (min-width: 540px) {
    /*turned to props for skills page */
    margin:${props => props.margin540 ? props.margin540: '3% .88% 1% 1%'};  
    /**made for use in skills page also being used in the porfolio page*/
    padding: ${props => props.padding540}; 
    font-size: ${props => props.fontsize540};
}
    /**medie querie min-width 768px */
@media screen and (min-width: 768px) {
    /*turned to props for skills page */
    margin:${props => props.margin768 ? props.margin768: '3% .88% 1% 1%'};  
    /**made for use in skills page also being used in the porfolio page*/
    padding: ${props => props.padding768}; 
    font-size: ${props => props.fontsize768};
}
 /**made for use in skills page*/
 @media screen and (min-width: 820px) {
    padding: ${props => props.padding820}; 
    font-size: ${props => props.fontsize820}; 
}
@media screen and (min-width: 1024px) {
width: ${props => props.width1024 };
/**Added JAN 23 2024*/
margin:${props => props.margin1024 };
}
@media screen and (min-width: 1280px) {
width: ${props => props.width1280 };
/**Added JAN 23 2024*/
margin:${props => props.margin1280 };
}

/*made for services originally */
@media screen and (min-width: 1366px) {
    padding: ${props => props.padding1366}; 
    font-size: ${props => props.fontsize1366};
    margin:${props => props.margin1366 };
}
@media screen and (min-width: 1920px) {
    padding: ${props => props.padding1920}; 
    font-size: ${props => props.fontsize1920};
    margin:${props => props.margin1920 };
}
@media screen and (min-width: 2400px) {
   
    margin:${props => props.margin2400 };
}
`;

export const ExternalLink = styled.a`
 text-decoration:${props => props.txtDecoration ? props.txtDecoration:'none'};
 color: ${props => props.color ? props.color: 'blue'};
`;

export const CallableLink = styled.a`
text-decoration: none;
color:${props => props.color ? props.color: '#f04b4c'};
`;


export const SocialImgLink = styled.a`
text-decoration: none;
//width: 50%;
margin: ${props => props.margin};
display: flex;
justify-content: center;
background: linear-gradient(rgba(61,96,152),rgba(33,49,89));
border-radius: 25px;
padding: 0%;

`;

export const ProjecInfoLink = styled.a`
   width: 100%;
    padding: 3px;
    //background-color: #00c3ff;
    font-size: 1em;
    color: #fee084;
    &:hover{
        color: #00c3ff;
    }  
    border-radius: 8px;
    //text-decoration: none;

`;

export const BkToTopLink = styled.a`
display: flex;
align-items: center;
color: white;
background-color: black;
border-radius: 50%;
border: 1px solid white;
padding: .5% .5% .5% .5%;
margin:0% 1% 0% 0% ;
@media screen and (min-width:1024px){
    padding: 0% 0% 0% 0%;
}
`;

export const SmallBckHmeLogoLink = styled(Link)`
margin:0% 0% 0% 1% ;
background-color: rgb(0,0,0);
border-radius: 50%;
//border: .5px solid white;
padding: .3% .3% .3% .5%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media screen and (min-width:768px){
    padding: 0% 0% 0% 0%;
}
`;
/**added 8-17-2023*/
export const LoginLink = styled(Link)`
color: rgba(61,96,152,1);
`;

