import decode from 'jwt-decode';

class AuthService {
  getProfile() {
    return decode(this.getToken());
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token');
  }
  getTempUserToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('userToken');
  }
  getTempProfileInfo() {
    return decode(this.getTempUserToken());
  }

  login(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
    // decode token so we can get the isAdmin property
      const decoded = decode(idToken);
  // if admin route to admin dashboard
  if(decoded.data.isAdmin) {
     window.location.replace('/admindashboard');
   }else {
    /*If regular user route to user profile page*/
    window.location.assign(`/profile/${decoded.data._id}`)
  }
  }
  tempCustomerogin(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('userToken', idToken);
    // decode token so we can get the isAdmin property
      const decoded = decode(idToken);

   
    /*route to customer dashboard page*/
    window.location.assign(`/dashboard/${decoded.data._id}`)
  
  }
  checkoutLogin(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
    window.location.assign('/');
  }
  /*This runs when then session timed out dialog okay button is clicked */
  sessionDidTimeOut(token) {

    /* this function will tell us if we are on the admin side of the app*/
    const urlIsAdmin = () => {
      /*admin related urls */
      const adminUrls = [
          "/admindashboard",
          "/userData",
          "/reviews",
          "/singleReview",
          "/createabout",
          "/editabout"
      ];
     for(let i = 0; i < adminUrls.length; i++) {
         const url = adminUrls[i];
         let regex =  RegExp(`${url}`);
         let adminUrlMatched = regex.test(window.location.href);
         if(adminUrlMatched === true) {
          return true;
         }
         
      };
             
        
  };
  const isAdmin = urlIsAdmin();

    const decoded = decode(token);
    /*get id from decoded token store in variable*/
    const usersId = decoded.data._id;
     // Clear user token and profile data from localStorage
     localStorage.removeItem('id_token');
     /* if page is the users profile page or it is an admin page go to login page*/
     if(window.location.pathname === `/profile/${usersId}` || isAdmin === true) {
      window.location.assign('/login');
       return;
     }
     // if the current page was not the users profile page or an admin page just reload
     window.location.reload();
    
  }
}

export default new AuthService();

