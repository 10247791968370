import { useState } from "react";
import { BasicDiv } from "../styles/Divs.styled";
import { Button } from '../styles/Buttons.styled';
import Dialog from '@mui/material/Dialog';
import Auth from "../../utils/auth";

const SessionTimeOutDialog = () => {
// state varible to open dialog
const [open,setOpen] = useState(false);
// dialog close function
const handleClose = () => {
    setOpen(false);
    /* get token so it can be passed to sessionDidTimeOut so that it can be decoded in that method*/
    const token = Auth.getToken();
    /*below will remove token from localStorage and bring user back to login page or reload current page depending on the page */
    Auth.sessionDidTimeOut(token);
  };

  /* function to check session probably need to have this run on each reload as well
  to handle a case when the user leaves without logging out*/
  const isSessionTimedOut = () => {
    /*If theres a token run the if */
    if(Auth.getToken()) {
      /*store token in var to pass to token status var*/
      const token = Auth.getToken();
      let tokenStatus = Auth.isTokenExpired(token);
      /*If token is expired run the if */
      if(tokenStatus === true) {
        /* if token is expired open dialog box*/
      setOpen(true);
      }
      return;
    }
return;
  };
  /*set timeout is for handling if a users session expires and they have left the app
  without logging out and then they return */
 setTimeout(() => {isSessionTimedOut();},1000);
  /*run interval one second after token expires */
 setInterval(() => {isSessionTimedOut();},1000 * 60 * 120 + 1000);
  
 

 

return(
<Dialog open={open}>
    <BasicDiv>Your session has expired you have been logged out</BasicDiv>
    {/*clicking ok will cause handle close to run in turn killing session */}
    <Button bgColor='#3d6098' onClick={() => handleClose()}>Ok</Button>
</Dialog>
)
};

export default SessionTimeOutDialog;