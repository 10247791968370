import styled from "styled-components";

export const Button = styled.button`
margin:${props => props.margin ? props.margin:"10% .88% 1% 1%"};
padding: 11px;
background-color: ${(props) => props.bgColor };
/*fonSize prop was created for the login button */
font-size:${props => props.fontSize ? props.fontSize: '1em'};
color: white;
opacity:${props => props.opacity ? props.opacity:".65"};
/**changed border on 1-13-24 */
border: none;
border-radius: 8px;
&:hover {
    opacity: 1;
    background-color: ${props => props.hoverClr};
}
/**medie querie min-width 768px */
@media screen and (min-width: 768px) {
    margin:3% .88% 1% 1%; 
    font-size:${props => props.fontSize768};
    padding:${props => props.padding768};
    border:${props => props.border768};
}
@media screen and (min-width: 912px) {
    font-size:${props => props.fontSize912};
    padding:${props => props.padding912};
}
/*query made for the login page*/
@media screen and (min-width: 1920px) {
    font-size:${props => props.fontSize1920};
    padding:${props => props.padding1920};
}
`;

export const ImgBtn = styled.button`
opacity: 1;
background-color: inherit;
border: none;
display: flex;
justify-content: center;
width: ${props => props.width};
padding: 0px;
margin: auto;
@media screen and (min-width: 768px) {
    width: ${props => props.width768};
}
`;

export const JoyRideTourBtn = styled.button`
background-color: transparent;
border: none;
color: #3fcbeb
`;

export const QuesFormBtn = styled.button`
padding: 11px;
margin-bottom: 25%;
color: white;
background-color: #3fcbeb;
border: 1px solid white;
&:hover {
    opacity: .5;
}
`;

export const VisitBtn = styled.button`
padding: 2.5%;
margin: 2% 0% 2% 42%;
background: rgba(61,96,152,1) ;
color: white;
border: none;
font-family:'manhattan';
border-radius: 8px;
font-size: 1em;
&:hover {
background: rgba(33,49,89);
color: white;
border: none;
font-weight: bolder;
};
@media screen and (min-width: 540px) {
    padding: 1.5%;
    font-size: 1.2em;
}
@media screen and (min-width: 768px) {
    margin: 2% 0% 2% 45%;
}
@media screen and (min-width: 1024px) {
    padding: .7%;
    font-size: .9em;
    margin-top: 0%;
    border: none;
    background: transparent;
}
@media screen and (min-width: 1280px) {
    padding: .4%;
   
    background: rgba(61,96,152,1);
    
}
@media screen and (min-width: 1366px) {
    padding: 1%;
};
@media screen and (min-width: 1920px) { 
    margin-top: 1%;
    padding: 1%;
}
`;
/**added 8-17-2023 */
export const PayBtn = styled.button`
color: white;
background-color: rgba(61,96,152,1);
padding: 2%;
border-radius: 8px;
border: 2px solid rgba(33,49,89);
&:hover{
    background-color: rgba(33,49,89);
    border: 2px solid rgba(61,96,152,1);
}
`;

/**Added Dec 9 2023 price pack category btn*/
export const PricePackBtn = styled.button`
background: linear-gradient(to right,rgb(33, 43, 89),rgb(61, 96, 152));
  color: whitesmoke;
  margin-bottom: .5em;
  font-family: 'cabrito didone';
  padding: 3%;
  border-radius: 11px;
  border: none;
  &:hover{
    background-color: rgba(33,49,89);
    
    color: #fee183;
}
@media screen and (min-width: 768px) {
    padding: 2%;
    font-size: 1.1em;
}
`;

export const TipsBtn = styled.button`
padding: 8px;
margin: 5% 0% 5% 0%;
color: white;
background-color: rgba(61, 96, 152);
border: none;
border-radius: 25px;
&:hover {
    background-color: rgba(33,49,89);
}
`;