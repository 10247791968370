import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom'

const style = {
  color: 'white',
  fontSize: '.7em',
  fontFamily:'manhattan',
  
  '@media(min-width: 360px)' : {
    fontSize:'1em'
}
};


const BasicBreadcrumbs = () => {
 let navigation = useNavigate();
  return (
    <div role="presentation">
      <Breadcrumbs sx={
        {
          fontSize:{sm:'1.5rem'},
           fontFamily:'manhattan',
           
           }
          } aria-label="breadcrumb">
      
        
        <Link onClick={() => navigation("/resources")} underline='always' color='inherit' sx={style} href='#'>
        
          Resources
        
          </Link>
          <Link onClick={() => navigation("/pricing")} sx={style} underline="always" color='inherit'  href='#'>
          
          pricing
     
          </Link>
          <Link onClick={() => navigation( "/about")} underline="always" color='inherit' sx={style} href='#'>
        
          About
       
          </Link>
       
        <Link onClick={() => navigation('/services')} underline="always" color='inherit' sx={style} href='#'>
      
          Services
        
        </Link>
        <Link onClick={() => navigation('/privacy-policy')} underline="always" color='inherit' sx={style} href='#'>
      
          Privacy
        
        </Link>
      </Breadcrumbs>
    </div>
  );
}

export default BasicBreadcrumbs;