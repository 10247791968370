import * as React from 'react';

import { SocialsDiv } from '../styles/Divs.styled';
import { SocialImgLink } from '../styles/Link.styled';
import { SocialPic } from '../styles/Images.styled';
/**icons */
import fb512px from '../../assets/icons/fb512px.png';
import fb256px from '../../assets/icons/fb256px.png';
import fb128px from '../../assets/icons/fb128px.png';
import fb64px from '../../assets/icons/fb64px.png';
import fb48px from '../../assets/icons/fb48px.png';

import insta48px from '../../assets/icons/insta48px.png';
import insta64px from '../../assets/icons/insta64px.png';
import insta128px from '../../assets/icons/insta128px.png';
import insta256px from '../../assets/icons/insta256px.png';
import insta512px from '../../assets/icons/insta512px.png';

import twitter48px from '../../assets/icons/twitter48.png';
import twitter64px from '../../assets/icons/twitter64.png';
import twitter128px from '../../assets/icons/twtr128px.png';
import twitter256px from '../../assets/icons/twtr256px.png';
import twitter512px from '../../assets/icons/twtr512px.png';

import tiktok48px from '../../assets/icons/tiktok48px.png';
import tiktok64px from '../../assets/icons/tiktok64px.png';
import tiktok128px from '../../assets/icons/tiktok128px.png';
import tiktok256px from '../../assets/icons/tiktok256px.png';
import tiktok512px from '../../assets/icons/tiktok512px.png';

import in48px from '../../assets/icons/in48px.png';
import in64px from '../../assets/icons/in64px.png';
import in128px from '../../assets/icons/in128px.png';
import in256px from '../../assets/icons/in256px.png';
import in512px from '../../assets/icons/in512px.png';

import gh48px from '../../assets/icons/github48px.png';
import gh64px from '../../assets/icons/github64px.png';
import gh128px from '../../assets/icons/github128px.png';
import gh256px from '../../assets/icons/github256px.png';
import gh512px from '../../assets/icons/github512px.png';

import yt48px from '../../assets/icons/youtube48px.png';
import yt64px from '../../assets/icons/youtube64px.png';
import yt128px from '../../assets/icons/youtube128px.png';
import yt256px from '../../assets/icons/youtube256px.png';
import yt512px from '../../assets/icons/youtube512px.png';



/**icons */
/*
srcSet={`${fb64px} 228w,${fb128px} 540w,${fb256px} 768w,${fb512px} 1366w`} 
 srcSet={`${twitter64px} 228w,${twitter128px} 540w,${twitter256px} 768w,${twitter512px} 1366w`} 
 srcSet={`${insta64px} 228w,${insta128px} 540w,${insta256px} 768w,${insta512px} 1366w`} 
 srcSet={`${tiktok64px} 228w,${tiktok128px} 540w,${tiktok256px} 768w,${tiktok512px} 1366w`} 
  srcSet={`${yt64px} 228w,${yt128px} 540w,${yt256px} 768w,${yt512px} 1366w`} 
  srcSet={`${in64px} 228w,${in128px} 540w,${in256px} 768w,${in512px} 1366w`} 
   srcSet={`${gh64px} 228w,${gh128px} 540w,${gh256px} 768w,${gh512px} 1366w`} 
*/

const SocialMenu = () => {
  
return(
    <SocialsDiv>
        <SocialImgLink margin='0em 0em 0em .2em' href='https://www.facebook.com/topdev11' target='_blank'>
            <SocialPic 
            src={fb48px} 
            srcSet={`${fb64px} `} 
            alt='fb link'
            />
        </SocialImgLink>
    
     
        <SocialImgLink margin='0em 0em 0em .2em' href='https://twitter.com/topdev_tech' target='_blank'>
            <SocialPic 
            src={twitter48px} 
            srcSet={`${twitter64px} `} 
            alt='fb link'
            />
        </SocialImgLink>
 
     
        <SocialImgLink margin='0em 0em 0em .2em' href='https://www.instagram.com/topdev.tech/' target='_blank'>
            <SocialPic 
            src={insta48px} 
            srcSet={`${insta64px} `} 
            alt='fb link'
            />
        </SocialImgLink>
  
     
        <SocialImgLink margin='0em 0em 0em .2em' href='https://www.tiktok.com/@topdev.tech?is_from_webapp=1&sender_device=pc' target='_blank'>
            <SocialPic 
            src={tiktok48px} 
            srcSet={`${tiktok64px}`} 
            alt='fb link'
            />
        </SocialImgLink>
 
        <SocialImgLink margin='0em 0em 0em .2em' href='https://www.youtube.com/channel/UCveOcrrMR_HzOqWn0_2k3_A' target='_blank'>
            <SocialPic 
            src={yt48px} 
            srcSet={`${yt64px} `} 
            alt='fb link'
            />
        </SocialImgLink>
   
   
        <SocialImgLink margin='0em .2em 0em .2em' href='https://www.linkedin.com/company/topdev-tech/' target='_blank'>
            <SocialPic 
            src={in48px} 
            srcSet={`${in64px} `} 
            alt='fb link'
            />
        </SocialImgLink>
    
     
        {/*<SocialImgLink margin='0em .2em 0em .2em' href='https://github.com/Sebastian2908-2007' target='_blank'>
            <SocialPic 
            src={gh48px} 
            srcSet={`${gh64px} `} 
            alt='fb link'
            />
        </SocialImgLink>*/}
  
        </SocialsDiv> 
)
};

export default SocialMenu;
/** 
    <picture>
            <source media="(min-width:200px)"  srcSet={in48px} type="image/png"/>
            <source media="(min-width:500px)"  srcSet={fb48px} type="image/png"/> 
  </picture>*/