import { useState } from 'react';
import {Head, Nav} from '../styles/Header.styled';
import BasicMenu from '../MUI/BasicMenu';
import { BasicDiv } from '../styles/Divs.styled';
import { StyledLink } from '../styles/Link.styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Auth from '../../utils/auth';
import { HeaderImg } from '../styles/Images.styled';
import SessionTimeOutDialog from '../MUI/BasicDialog';
//import SocialMenu from '../MUI/SocialMenu';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
/*Quick note about the logo originally I had it like this: ./topDev-header-logo.png
But when on the user profile if a refresh happend it said it could not be found so I changed it to this:
/topDev-header-logo.png it seems proper now sooo yeah */
const Header = ({userPics,profilePicInfo}) => {
    /*MODAL RELATED FUNCTIONALITY*/
    const [shouldDelete,setShouldDelete] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleCloseNoDelete = () => {setShouldDelete(false); setOpen(false); }
    const handleCloseYesDelete = () =>  { setShouldDelete(true); setOpen(false); }
   /*MODAL RELATED FUNCTIONALITY*/
  
   if(Auth.loggedIn()) {
    var isAdmin = Auth.getProfile().data.isAdmin;
   };
    return (
    <Head id='#'>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Account?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Deleting your account is an action that cannot be reversed are you sure you wish to proceed?
          </Typography>
          <Button onClick={handleCloseYesDelete}>Yes</Button>
          <Button onClick={handleCloseNoDelete}>No</Button>
        </Box>
    </Modal>
    { isAdmin ? ( 
        <StyledLink to='/admindashboard'>
        <BasicDiv>Admin Dash</BasicDiv>
        </StyledLink> ):(
        <StyledLink to='/'>
        <BasicDiv><HeaderImg fetchpriority="high" src='/new-TD-logo-300.webp' alt='logo'/></BasicDiv>
        </StyledLink>)}
        <Nav>
         {/* <SocialMenu/>*/}
            <BasicMenu shouldDelete={shouldDelete} 
            setShouldDelete={setShouldDelete}
             handleOpen={handleOpen}
             /*userPics are set in the userImgs component located in the profile page
             the variable itself lives in app.js it's purpose is for deleting
             user associated images from s3 on account deletion */
             userPics={userPics}
             /**here profile pic info is being passed from app and set in the same place as all other images */
             profilePicInfo={profilePicInfo}
             />
        </Nav>
        <SessionTimeOutDialog/>
    </Head>
    );
};

export default Header;
//<BasicDiv>TopDev</BasicDiv>