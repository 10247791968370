import { useState,lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider, ApolloClient,createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context';
import { HelmetProvider } from 'react-helmet-async';
import { onError } from "@apollo/client/link/error";
import Header from './components/Header';
import Footer from './components/Footer';

const ProjectDetails  = lazy(() => import(  './components/pages/ProjectDetails'));
const AdminDashboard  = lazy(() => import(  './components/pages/AdminDashboard'));
const AdminViewUserData  = lazy(() => import(  './components/pages/AdminViewUserData'));
const Reviews  = lazy(() => import(  './components/Reviews'));
const SingleReview  = lazy(() => import(  './components/pages/SingleReview'));
const Services  = lazy(() => import(  './components/pages/Services'));
const WebsiteAppDifference  = lazy(() => import(  './components/pages/WebsiteAppDifference'));
const Pricing  = lazy(() => import(  './components/pages/Pricing'));
const CreateAbout  = lazy(() => import(  './components/pages/CreateAbout'));
const EditAbout  = lazy(() => import(  './components/pages/EditAbout'));
const AdminWarmLeads  = lazy(() => import(  './components/pages/AdminWarmLeads'));


//import PaymentPage from './components/pages/PaymentPage';
const Page404  = lazy(() => import(  './components/pages/Page404'));
const ResourceLayout  = lazy(() => import(  './layouts/ResourceLayout'));
const Faq  = lazy(() => import(  './components/pages/Faq'));
const CompaniesTechStacks  = lazy(() => import( './components/pages/CompaniesAndTechStacks'));
const FreeStuff  = lazy(() => import( './components/pages/FreeStuff'));
/**added 8-17-2023 */
const AddPricePack  = lazy(() => import(  './components/pages/AddPricePack'));
const HomePage = lazy(() => import( './components/pages/HomePage'));
const Signup = lazy(() => import( './components/pages/Signup'));
const Login = lazy(() => import( './components/pages/Login'));
const Profile = lazy(() => import( './layouts/Profile'));
const FeatureBoard = lazy(() => import( './components/pages/FeatureBoard'));
const PrivacyPolicy = lazy(() => import( './components/pages/PrivacyPolicy'));
//const AboutPage = lazy(() => import('./components/pages/AboutPage'));
const About = lazy(() => import('./components/pages/About'));
const SingleAbout = lazy(() => import('./components/pages/SingleAbout'));
const Seo = lazy(() => import('./components/pages/Seo'));
/**Added October 2024 */
const TemplatesPage = lazy(() => import('./components/pages/TemplatesPage'));
const TempSuccess = lazy(() => import('./components/pages/TempSuccess'));
const Dashboard  = lazy(() => import(  './layouts/Dashboard'));
const AdminTempCustomer  = lazy(() => import(  './components/pages/AdminTempCustomer'));


// establish connection to /graphql endpoint
const httpLink = createHttpLink({
   uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// client prop to be passed in to ApolloProvider in order to give app access to all endpoint data
const client = new ApolloClient({
  link: from([errorLink,authLink, httpLink]),
  cache: new InMemoryCache()
});

function App() {
  // this will be state for checkingwhether or not and about form whether edit create or pic 
  // or video was submitted its main use is to cause a refetch if this works
  // then I may refrac some of my other code to possibly use the same variable in order to follow more
  // DRY principals
  const [formSubmitted,setFormSubmitted] = useState(false);

   /*This will live in app js I will pass set user pics to profile page where the get pics for delete query
   will be called I will setUserPics with the result userPics will be passed to header where it will ultimately be passed
   to the basic menu where on the ocassion of account delete the pics will be available */
   const [userPics,setUserPics] = useState([]);
   const [profilePicInfo,setProfilePicInfo] = useState(null);
   /**these props are for the pricing package the data is used when creating a Contact/user */
   const [pricePack,setPricePack] = useState(undefined);
   /**added 8-17-2023 */
   const [pricePackages,setPricePackages] = useState([]);
   const [pricePackName,setPricePackName] = useState(undefined);
   const [pricePackID,setPricePackID] = useState(undefined);
   //setup type state
   const  [packType,setPackType] = useState(null);

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
      <Router>
        <Header userPics={userPics} profilePicInfo={profilePicInfo}/>
        <Suspense fallback={<div>Loading...</div>}>
      <Routes>
 <Route exact path="/" element={ <HomePage/> }/>
 <Route exact path="/signup" element={ <Signup pricePack={pricePack}setPricePack={setPricePack}
 pricePackages={pricePackages}setPricePackages={setPricePackages}
 pricePackName={pricePackName} setPricePackName={setPricePackName}
 pricePackID={pricePackID} setPricePackID={setPricePackID}
 
 packType={packType}setPackType={setPackType}
 />}/>
 <Route exact path="/login" element={ <Login/> }/>
 <Route
  path="/profile/:_id"
   element={
     <Profile  setUserPics={setUserPics} setProfilePicInfo={setProfilePicInfo}/>
      }
  >
     <Route path='featureboard' element={<FeatureBoard/>}/>
     <Route path='projectdetails' element={<ProjectDetails/>}/>
  </Route>

  <Route
  path="/dashboard/:_id"
   element={
     <Dashboard/>
      }
  ></Route>

 <Route path='/resources' element={<ResourceLayout/>}>
 <Route path='faq' element={<Faq/>}/>
 <Route path='companies-and-tech-stacks' element={<CompaniesTechStacks/>}/> 
 <Route path="freestuff" element={ <FreeStuff/> }/>
 <Route path="seo-utah" element={ <Seo/> }/>
 </Route>
 <Route exact path="/services" element={ <Services/> }/>
 <Route exact path="/difference-in-websites-and-web-apps" element={ <WebsiteAppDifference/> }/>
 <Route exact path="/pricing" element={ <Pricing pricePack={pricePack}setPricePack={setPricePack}
  pricePackages={pricePackages}setPricePackages={setPricePackages}
  pricePackName={pricePackName} setPricePackName={setPricePackName}
  pricePackID={pricePackID} setPricePackID={setPricePackID}
  
  packType={packType}setPackType={setPackType}
  />
  }/>
  
 {/* original about page de commissioned 9-8-2023 <Route exact path="/abouttopdev" element={ <AboutPage/> }/>*/}
 <Route exact path="/about" element={ <About/> }/>
 <Route exact path="/seo-templates" element={ <TemplatesPage/> }/>
 <Route exact path="/success" element={ <TempSuccess/> }/>
 <Route exact path="/privacy-policy" element={ <PrivacyPolicy/> }/>
 <Route exact path="/about/:_id/:title" element={ <SingleAbout/> }/>
 {/*<Route exact path="/payment" element={ <PaymentPage/> }/>*/}
 <Route exact path="*" element={ <Page404/> }/>
 {/*Admin side of the app ====================================== */}
 {/** added 8-17-2023  */}
 <Route exact path="/addpricepack" element={ <AddPricePack/> }/>
 <Route exact path="/admindashboard" element={ <AdminDashboard/> }/>
 <Route exact path="/AdminWarmLeads" element={ <AdminWarmLeads/> }/>
 <Route exact path="/userData/:_id" element={ <AdminViewUserData/> }/>
 <Route exact path="/reviews" element={ <Reviews/> }/>
 <Route exact path="/singleReview/:_id" element={ <SingleReview/> }/>
 <Route exact path="/createabout" element={ <CreateAbout formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted}/> }/>
 <Route exact path="/editabout/:_id" element={ <EditAbout formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted}/> }/>
  <Route exact path="/admin-template-customers" element={ <AdminTempCustomer/> }/>
  {/*Admin side of the app ====================================== */}

     </Routes>
     </Suspense>
     <Footer></Footer>
      </Router>
      </HelmetProvider>
    </ApolloProvider>
  );
}

export default App;

