import styled from "styled-components";

/* I should put all divs in this file*/

export const BasicDiv = styled.div`
transform: ${props => props.translate};
/*font size prop made for the profile page also used in signup error div*/
font-size: ${props => props.fontSize};
margin: ${props => props.margin ? props.margin: '.11%'};
/*padding prop made for the loginBasicDiv */
padding: ${props => props.padding ? props.padding: '.75%'};
color: ${props => props.color ? props.color: '#3d6098'};
background: ${props => props.bgColor};
text-align: ${props => props.textAlign};
border-radius: ${props => props.borderRadius};
border:${props => props.border};
box-shadow: ${props => props.boxShadow};
@media screen and (min-width: 540px) {
    /*padding left and right were created mainly for the footer */
    padding-right: ${props => props.paddingRightMd};
    padding-left: ${props => props.paddingLefttMd};
    width: ${props => props.width540};
}
@media screen and (min-width: 768px) {
    /*fontsize prop made for profile page */
    font-size: ${props => props.fontSize768};
    border:${props => props.border768};
    box-shadow: ${props => props.boxShadow768};
}
/*query made for login page */
@media screen and (min-width: 1920px) {
    border:${props => props.border1920};
    box-shadow: ${props => props.boxShadow1920};
    /*made for profile page */
    font-size: ${props => props.FontSize1920};

}
`;

export const FlexDiv = styled.div`
/**added Aug 27  2023*/
width: ${props => props.width};

border: ${props => props.border};
box-shadow: ${props => props.boxShadow};
border-radius: ${props => props.borderRadius};
margin: 0 .75%;
margin-top: ${props => props.marginTop};
padding: ${props => props.padding ? props.padding: '.85%'};
padding-bottom: ${props => props.paddingBtm};
display: flex;
flex-direction: column;
/*props put on justify-content specifically for error 404 page */
justify-content:${props => props.justifyContent ? props.justifyContent: 'center'};
align-items:${props => props.alignItems ? props.alignItems:"center"};
/**made height based on props just for services page */
height:${props => props.height ? props.height: '100%'};
color: white;
@media screen and (min-width: 768px) {
    /**these styles are currently only being used in portfolio page */
    width: ${props => props.width};
    margin-right: ${props => props.mrginRht};
    margin-left: ${props => props.mrgnLft};
    margin-bottom: ${props => props.mrgnBtm};
    margin-top: ${props => props.mrgnTopMd ? props.mrgnTopMd:props.marginTop};
     /**these styles are currently only being used in portfolio page */
     /*used for abouts component */
     border: ${props => props.border768};
}
/*all media queries below were mainly defined to adress the cta buttons in the services page */
@media screen and (min-width: 820px) {
    margin-top: ${props => props.marginTop820};
    /**added oct 2024 */
    margin: ${props => props.margin820  ? props.margin820: '0 .75%'}
}
/*made for services page originally */
@media screen and (min-width: 912px) {
    margin-top: ${props => props.marginTop912};
}
@media screen and (min-width: 1024px) {
    margin-top: ${props => props.marginTop1024}; 
    width:${props => props.width1024};
    /**added oct 2024 */
    padding:${props => props.padding1024};
}
@media screen and (min-width: 1280px) {
    margin-top: ${props => props.marginTop1280}; 
     /**added oct 2024 */
     padding:${props => props.padding1280};
}
@media screen and (min-width: 1366px) {
    margin-top: ${props => props.marginTop1366}; 
     /**added oct 2024 */
     padding:${props => props.padding1366};
}
@media screen and (min-width: 1920px) {
    margin-top: ${props => props.marginTop1920}; 
     /**added oct 2024 */
     padding:${props => props.padding1920};
}
/**breakpoint added oct 2024 relating to hero image*/
@media screen and (min-width: 2560px) {
     /**added oct 2024 */
     padding:${props => props.padding2560};
}
`;

export const RowDiv = styled.div`
/**adding width prop for about section on create about page */
width: ${props => props.width};
display: flex;
/**made for profile page name heading original was flex-direction: row;*/
flex-flow:${props => props.flexFlow ? props.flexFlow: 'row nowrap'};
flex-basis: ${props => props.flexBasis};
/**made for profile page name heading original was flex-direction: row; */
justify-content: ${(props) => props.justifyContent };
border: ${props => props.border};
align-items: ${props => props.alignItems};
/*all media queries below were mainly defined to adress the cta buttons in the services page */
@media screen and (min-width: 768px) {
    margin-top: ${props => props.marginTop};
    width: ${props => props.width768};
    justify-content:${props => props.justifyContent768}
   
}
@media screen and (min-width: 820px) {
    margin-top: ${props => props.marginTop820};
}
/*made for services page originally */
@media screen and (min-width: 912px) {
    margin-top: ${props => props.marginTop912};
}
@media screen and (min-width: 1024px) {
    margin-top: ${props => props.marginTop1024}; 
}
@media screen and (min-width: 1280px) {
    margin-top: ${props => props.marginTop1280}; 
}
@media screen and (min-width: 1366px) {
    margin-top: ${props => props.marginTop1366}; 
}
@media screen and (min-width: 1920px) {
    margin-top: ${props => props.marginTop1920}; 
}
`;
export const RowDivServiceHero = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
margin-top: ${props => props.marginTop};
@media screen and (min-width: 768px) {
    justify-content: ${(props) => props.justifyContent };
    padding-right: ${props => props.paddingRight};
    margin-top: 8rem;
}
@media screen and (min-width: 1920px) {
    margin-top: 11rem;
}
`;
export const RowDivPortfolioHero = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
margin-top: ${props => props.marginTop };
@media screen and (min-width: 768px) {
    margin-top:1.5rem;
}
`;
/**price card related*/

/**Below wraps next two underneath */
export const PriceExampleRowDiv = styled.div`
display: flex;
flex-direction: row;
`;
/**>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
export const  PackagePriceDiv = styled.div`
display: flex;
flex-direction: column;
padding: 5%;
width: 90%;
height: 30%;
color:${props => props.color ? props.color: '#ff9d00'};
border:${props => props.border ? props.border: '1px solid #ff9d00'};
transform: ${props => props.translate} ;
@media screen and (min-width: 360px) {
    transform: ${props => props.translate360px};
}
@media screen and (min-width: 375px) {
    transform: ${props => props.translate375px};
}
@media screen and (min-width: 390px) {
    transform:${props => props.translate390px};
}
@media screen and (min-width: 540px) {
    transform:${props => props.translate540px} ;
}
@media screen and (min-width: 768px) {
    transform:${props => props.translate768px} ;
}
@media screen and (min-width: 820px) {
    transform: ${props => props.translate820px};
}
@media screen and (min-width: 912px) {
    transform: ${props => props.translate912px};
}
@media screen and (min-width: 1024px) {
    transform: ${props => props.translate1024px};
}
@media screen and (min-width: 1280px) {
    transform: ${props => props.translate1280px};
}
@media screen and (min-width: 1366px) {
    transform: ${props => props.translate1366px};
}
@media screen and (min-width: 1920px) {
    transform: ${props => props.translate1920px};
}
@media screen and (min-width: 2560px) {
    transform: ${props => props.translate2560px};
}
`;



export const PackageExamplesDiv = styled.div`
display: flex;
flex-direction: column;
color: #e7e7e7;
box-shadow: ${props => props.boxShadow ? props.boxShadow:'0 0 5px #e7e7e7'};
padding: 3%;
background-color: ${props => props.bgColor};
`;
/**>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/**Below wraps next two underneath ends*********************** */

export const PricePackageGridsDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
`;

/**made for updated homepage content */
export const HomePageMainContentDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
`;

export const ErrorDiv = styled.div`
background-color: #f04b4c;
color: white;
`;
/**added June 13 2023 */
/**this will also be used in resource content */
export const DecorativeDiv = styled.div`
grid-area:${(props) => props.gridArea };
display: flex;
flex-direction: row;
width: ${props => props.width };
border:${props => props.border ? props.border:' 1px solid #e7e7e7'};
border-radius: 10px;
box-shadow:${props => props.boxShadow ? props.boxShadow:' 0 0 10px #e7e7e7'};

background: ${props => props.bckGround ? props.bckGround:'linear-gradient(to left, rgba(61,96,152,1),rgba(33,49,89,1))'};
@media screen and (min-width:540px) {
    width: ${props => props.width540};
}
@media screen and (min-width:912px) {
    width: ${props => props.width912};
}
@media screen and (min-width:1024px) {
    width: ${props => props.width1024}
}
@media screen and (min-width: 1920px) {
    width: ${props => props.width1920};
}
`;
/**this will also be used in resource content */
export const InnerDecorativeListDiv = styled.div`
width: 50%;
display: flex;
flex-direction: column;
text-align: center;
padding: 1%;
`;
/**this will also be used in resource content */
export const InnerDecorativeImgDiv = styled.div`
width: 50%;
`;

export const CarouselGridDiv = styled.div`
border-radius: ${props => props.borderRadius};
display: ${props => props.display};
flex-direction: ${props => props.flexDir};
justify-content: ${props => props.jstCntnt};
align-items: ${props => props.alignItms};
grid-area:${(props) => props.gridArea };
background:${props => props.backGround};
box-shadow: ${props => props.bxShadow};
padding: ${props => props.padding};
height: ${props => props.height};
@media screen and (min-width: 1024px) {
margin-left: ${props => props.mrgnLft};
margin-right: ${props => props.mrgnRght};
}
@media screen and (min-width:912px) {
    padding: ${props => props.pddng912};
    height: ${props => props.height912};
}
@media screen and (min-width:1024px) {
    height: ${props => props.height1024};
}
@media screen and (min-width:1280px) {
    height: ${props => props.height1280};
}
@media screen and (min-width:1366px) {
    height: ${props => props.height1366};
}
@media screen and (min-width:1920px) {
    height: ${props => props.height1920};
    flex-direction: ${props => props.flexDir1920};
    padding: ${props => props.padding1920};
    //width: 60%;
}
`;
export const DefaultResourceDiv = styled.div`
padding: 2%;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
//box-shadow: 0 0 10px #fee084;
@media screen and (min-width: 540px) {
    justify-content: space-around;
}
`;
export const ThreeDLogoDiv = styled.div`
position: relative;
height: 100%;
width:100%;
`;
/**added June 13 2023 END!!!!!**/
export const PriceInfoDiv = styled.div`
padding: 2%;
display: flex;
flex-direction: column;
align-items: center;
@media screen and (min-width: 1366px) {
padding: 0%;
}
`;
export const InnerPriceInfoDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 3%;
@media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 5%;
  
}
`;
/**added 8-17-2023*/

export const AddBenifitsDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 3%;
height: auto;
width: 100%;
`;
export const DisplayBenifitsDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2%;
height: auto;
background-color: white;
padding: 1%;
width: 100%;
`;
/**added aug 20, 2023 */
export const SubPriceInfoDiv = styled.div`
padding: 2%;
display: flex;
flex-direction: column;
align-items: center;
@media screen and (min-width: 768px) {
flex: 1 1 0px;
}
`;
export const IconDiv = styled.div`
color: #fee084;
background: linear-gradient(195deg,rgba(0,0,0) ,rgba(61,96,152,1) ,rgba(33,49,89));
border-radius: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 2%;
@media screen and (min-width: 768px) {
    padding: 5%;
};
`;

export const FAQFlexColDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2%;
height: auto;
padding: 1%;
width: 100%;
`;
/**added Aug 29 2023*/
export const ProjectDetailsDiv = styled.div`
width: ${props => props.width};
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
color: white;
margin-top: ${props => props.marginTop};
margin-bottom: 5%;
padding: ${props => props.padding};
@media screen and (min-width: 540px) {
flex-direction:${props => props.flexDir540 ? props.flexDir540:'row'};
}
`;

/**Added September 7 2023*/
export const AboutIconDiv = styled.div`
color: rgba(33,49,89);
background-color: white;
border-radius: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 1%;
@media screen and (min-width: 768px) {
    //padding: 5%;
};
&:hover {
    background-color: #fee184; 
   
}
`;
/**Added Dec 9 2023 price pack category7 btn div*/
export const PricePackBtnDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 5em;
padding: 1em;
@media screen and (min-width: 360px) {
    flex-direction: row;
    justify-content: space-between;
};
`;
export const LargeRowDiv = styled.div`

@media screen and (min-width: 1024px) {
    display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
width:100%;

};
`;
/**jan 24 */
export const FomoDiv = styled.div`
background-color: black;
color: #fee084;
width: 70%;
opacity: .45;
font-family: "manhattan";
padding: 2%;
font-weight: 400;
border-radius: 8px;
text-align: center;
@media screen and (min-width: 1024px) {
    
}
`;
export const PriceImgDiv = styled.div`
width: 90%;
padding: 2%;
@media screen and (min-width: 768px) {
    width: 50%;
}
@media screen and (min-width: 1366px) {
    width: 100%;
    padding: 0%;
}
@media screen and (min-width: 1920px) {
    width: 80%;
    padding: 0%;
}
@media screen and (min-width: 2200px) {
    width: 70%;
    padding: 0%;
}
`;
export const PriceValueFlexDiv = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
@media screen and (min-width: 768px) {
    flex-direction: row;
}
@media screen and (min-width: 1366px) {
    flex-direction: column;
    margin-bottom: ${props => props.margin1366};
}
`;

/**JAN 23 2024 */
export const NewCtaDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
transform: translate(0%, -95%);
position: absolute;
padding: 11%;
@media screen and (min-width: 360px) {
    transform: translate(10%, -105%);
};
@media screen and (min-width: 390px) {
    transform: translate(15%, -105%);
};
@media screen and (min-width: 430px) {
    transform: translate(20%, -105%);
};
@media screen and (min-width: 540px) {
    transform: translate(33%, -105%);
};
@media screen and (min-width: 768px) {
    transform: translate(0%, -100%);
    padding: 15%
};
@media screen and (min-width: 1024px) {
    transform: translate(10%, -100%);
    padding: 15%
};
@media screen and (min-width: 1280px) {
    transform: translate(15%, -100%);
};
@media screen and (min-width: 1366px) {
    transform: translate(20%, -100%);
};
@media screen and (min-width: 1920px) {
    transform: translate(35%, -100%);
};
@media screen and (min-width: 2400px) {
    transform: translate(45%, -100%);
};

`;

export const SocialsDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: .3em 0em .3em 0em;
@media screen and (min-width: 540px) {
 width: 95%;
}
//padding: 2%;
`;
export const FooterFlexDiv = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
@media screen and (min-width: 540px) {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 width: 100%;
}
`;
/*Added Feb 2024*/
export const WebInfoDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 3%;
width: 85%;
margin: 4% 0% 4% 0%;
`;