import { Toe } from "../styles/Footer.styled";
import { BasicDiv, FooterFlexDiv } from "../styles/Divs.styled";
import BasicBreadcrumbs from "../MUI/BasicBreadCrumbs";
import SocialMenu from "../MUI/SocialMenu";

const Footer = () => {
    return(
        <Toe>
         <FooterFlexDiv>
        <BasicDiv
             color='white' 
            paddingLefttMd='3%' 
            margin='0%' 
            textAlign='center'
            > &copy; TopDev Tech &nbsp;{new Date().getFullYear()}
        </BasicDiv>
            <BasicDiv paddingRightMd='3%' margin='0%' >
                <BasicBreadcrumbs/>
          </BasicDiv>
            </FooterFlexDiv>
            <SocialMenu/>
        </Toe>
    );
};

export default Footer;